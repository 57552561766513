import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Flex,
  VStack,
  Heading,
  Button,
  Container,
} from "@chakra-ui/react";
import { Mail } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import Lottie from "lottie-react";
import { getText } from "./translations";
import animationData from "./animation/animation1.json";
import background1 from "./files/background1.jpeg";
import background2 from "./files/background2.jpg";
import background3 from "./files/background3.jpg";
import background4 from "./files/background4.jpg";

const images = [background1, background2, background3, background4];

export default function Hero({ onNotificationModalOpen, currentLanguage }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAutoScrolling, setIsAutoScrolling] = useState(true);
  const autoScrollIntervalRef = useRef(null);

  const startAutoScroll = useCallback(() => {
    if (autoScrollIntervalRef.current)
      clearInterval(autoScrollIntervalRef.current);
    autoScrollIntervalRef.current = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
  }, []);

  const stopAutoScroll = () => {
    if (autoScrollIntervalRef.current) {
      clearInterval(autoScrollIntervalRef.current);
      autoScrollIntervalRef.current = null;
    }
  };

  const handleImageHold = () => {
    setIsAutoScrolling(false);
    stopAutoScroll();
  };

  const handleImageRelease = () => {
    setIsAutoScrolling(true);
    startAutoScroll();
  };

  useEffect(() => {
    if (isAutoScrolling) {
      startAutoScroll();
    }

    return () => stopAutoScroll();
  }, [isAutoScrolling, startAutoScroll]);

  return (
    <Flex
      id="home"
      minHeight="100vh"
      flexDirection={{ base: "column", md: "row" }}
    >
      <Box
        position="relative"
        width={{ base: "100%", md: "60%" }}
        height={{ base: "50vh", md: "100vh" }}
      >
        <AnimatePresence mode="wait">
          <motion.img
            key={currentImageIndex}
            src={images[currentImageIndex]}
            alt={`Background ${currentImageIndex + 1}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            onMouseDown={handleImageHold}
            onMouseUp={handleImageRelease}
            onTouchStart={handleImageHold}
            onTouchEnd={handleImageRelease}
          />
        </AnimatePresence>
      </Box>

      <Flex
        width={{ base: "100%", md: "40%" }}
        bg="rgba(0, 0, 0, 0.5)"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 4, md: 8 }}
        py={{ base: 8, md: 0 }}
      >
        <Container maxWidth="100%">
          <VStack
            spacing={6}
            align="center"
            justify="center"
            textAlign="center"
          >
            <Heading
              as="h1"
              fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
              color="white"
            >
              {getText("welcome", currentLanguage)}
            </Heading>
            <Heading
              as="h2"
              fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
              color="white"
              fontWeight="normal"
            >
              {getText("tagline", currentLanguage)}
            </Heading>
            <Box
              width={{ base: "120px", sm: "150px", md: "180px" }}
              height={{ base: "60px", sm: "75px", md: "90px" }}
            >
              <Lottie
                animationData={animationData}
                loop
                autoplay
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </Box>
            <Button
              onClick={onNotificationModalOpen}
              size={{ base: "md", sm: "lg" }}
              colorScheme="blue"
              leftIcon={<Mail className="mr-2 h-4 w-4" />}
            >
              {getText("notifyMe", currentLanguage)}
            </Button>
          </VStack>
        </Container>
      </Flex>
    </Flex>
  );
}
