import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Button,
  IconButton,
  HStack,
  VStack,
  useTheme,
  useBreakpointValue,
  Collapse,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { FaGlobe } from "react-icons/fa";
import { getText } from "./translations";
import logo from "./files/logo.jpeg";

const languages = [
  { code: "en", name: "English" },
  { code: "es", name: "Español" },
  { code: "fr", name: "Français" },
  { code: "de", name: "Deutsch" },
  { code: "hi", name: "हिन्दी" },
];

export default function Header({ currentLanguage, setCurrentLanguage }) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isMobile) {
      setIsOpen(false);
    }
  }, [isMobile]);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      if (isMobile) {
        setTimeout(() => {
          setIsOpen(false);
        }, 300);
      }
    }
  };

  const primaryColor = theme.colors?.primary?.main || "#3182CE";
  const secondaryColor = theme.colors?.secondary?.main || "#2D3748";
  const backgroundColor = theme.colors?.background?.default || "#FFFFFF";
  const textColor = theme.colors?.text?.primary || "#1A202C";

  const LanguageMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const isMobile = useBreakpointValue({ base: true, md: false });

    const handleOpen = () => setMenuOpen(true);
    const handleClose = () => setMenuOpen(false);

    return (
      <Menu isOpen={menuOpen} onClose={handleClose}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          leftIcon={<FaGlobe />}
          variant="outline"
          color={secondaryColor}
          borderColor={secondaryColor}
          _hover={
            !isMobile && { bg: `${primaryColor}33`, color: secondaryColor }
          }
          _expanded={{ bg: `${primaryColor}33`, color: secondaryColor }}
          onMouseEnter={!isMobile ? handleOpen : undefined}
          onMouseLeave={!isMobile ? handleClose : undefined}
          onClick={isMobile ? (menuOpen ? handleClose : handleOpen) : undefined}
        >
          {getText("selectLanguage", currentLanguage)}
        </MenuButton>
        <MenuList
          bg={backgroundColor}
          color={secondaryColor}
          borderColor={primaryColor}
          boxShadow="0 2px 8px rgba(0, 0, 0, 0.2)"
          onMouseEnter={!isMobile ? handleOpen : undefined}
          onMouseLeave={!isMobile ? handleClose : undefined}
        >
          {languages.map((lang) => (
            <MenuItem
              key={lang.code}
              onClick={() => {
                setCurrentLanguage(lang.code);
                if (isMobile) handleClose();
              }}
              color={secondaryColor}
              bg={backgroundColor}
              _hover={{ bg: `${primaryColor}33`, color: secondaryColor }}
            >
              {lang.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  };

  return (
    <Box
      as="header"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={10}
      bg="transparent"
      transition="background-color 0.3s ease"
    >
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="1.5rem"
        color={secondaryColor}
      >
        <Flex align="center" mr={isMobile ? 0 : 5}>
          <Box
            as="img"
            src={logo}
            alt="Logo"
            h={10}
            mr={3}
            boxShadow={`0px 4px 8px ${backgroundColor}`}
            transform="translateY(-4px)"
            transition="transform 0.3s ease, box-shadow 0.3s ease"
            borderRadius="4px"
          />
          <Heading
            as="h1"
            size="lg"
            letterSpacing={"-.1rem"}
            fontFamily={theme.fonts?.heading || "inherit"}
          >
            Vibeinn
          </Heading>
        </Flex>

        {isMobile && (
          <IconButton
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            onClick={toggleMenu}
            variant="outline"
            aria-label="Menu"
            display={{ md: "none" }}
            color={secondaryColor}
            borderColor={secondaryColor}
            _hover={{ bg: `${primaryColor}33` }}
          />
        )}

        <HStack spacing={4} display={{ base: "none", md: "flex" }}>
          <Button
            onClick={() => scrollToSection("home")}
            variant="ghost"
            color={secondaryColor}
            _hover={{ bg: `${primaryColor}33` }}
          >
            {getText("home", currentLanguage)}
          </Button>
          <Button
            onClick={() => scrollToSection("features")}
            variant="ghost"
            color={secondaryColor}
            _hover={{ bg: `${primaryColor}33` }}
          >
            {getText("features", currentLanguage)}
          </Button>
          <LanguageMenu />
        </HStack>

        {/* Drawer for mobile view */}
        <Drawer isOpen={isOpen} placement="left" onClose={toggleMenu}>
          <DrawerOverlay>
            <DrawerContent bg={backgroundColor} color={secondaryColor}>
              <DrawerCloseButton color={secondaryColor} />
              <DrawerHeader>
                <Flex align="center">
                  <Box
                    as="img"
                    src={logo}
                    alt="Logo"
                    h={10}
                    mr={3}
                    borderRadius="4px"
                  />
                  <Heading
                    as="h1"
                    size="lg"
                    fontFamily={theme.fonts?.heading || "inherit"}
                  >
                    Vibeinn
                  </Heading>
                </Flex>
              </DrawerHeader>
              <DrawerBody>
                <VStack spacing={4} align="stretch">
                  <Button
                    onClick={() => scrollToSection("home")}
                    variant="ghost"
                    color={secondaryColor}
                    _hover={{ bg: `${primaryColor}33` }}
                    width="100%"
                    textAlign="left"
                  >
                    {getText("home", currentLanguage)}
                  </Button>
                  <Button
                    onClick={() => scrollToSection("features")}
                    variant="ghost"
                    color={secondaryColor}
                    _hover={{ bg: `${primaryColor}33` }}
                    width="100%"
                    textAlign="left"
                  >
                    {getText("features", currentLanguage)}
                  </Button>
                  <LanguageMenu />
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Flex>
    </Box>
  );
}
