import React, { useState } from "react";
import { ChakraProvider, CSSReset, Box } from "@chakra-ui/react";
import { theme } from "./theme";
import Header from "./Header";
import Hero from "./Hero";
import AboutUs from "./AboutUs";
import Features from "./Features";
import Footer from "./Footer";
import NotificationModal from "./NotificationModal";
import { useScrollEffect } from "./utils";

export default function App() {
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const scrolled = useScrollEffect();

  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Box>
        <Header
          scrolled={scrolled}
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
        />
        <Hero
          onNotificationModalOpen={() => setIsNotificationModalOpen(true)}
          currentLanguage={currentLanguage}
        />
        <AboutUs currentLanguage={currentLanguage} />
        <Features currentLanguage={currentLanguage} />
        <Footer currentLanguage={currentLanguage} />
        <NotificationModal
          isOpen={isNotificationModalOpen}
          onClose={() => setIsNotificationModalOpen(false)}
          currentLanguage={currentLanguage}
        />
      </Box>
      <style jsx global>{`
        @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Emblema+One&display=swap");

        html,
        body {
          font-family: "DM Sans", sans-serif;
        }
      `}</style>
    </ChakraProvider>
  );
}
