import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    primary: {
      main: "#4A90E2",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: {
      default:
        "linear-gradient(to bottom, rgba(0, 20, 40, 0.8), rgba(0, 10, 20, 0.9))",
    },
  },
  fonts: {
    heading: "'DM Sans', sans-serif",
    body: "'DM Sans', sans-serif",
  },
  components: {
    Button: {
      baseStyle: {
        borderColor: "#FFFFFF",
      },
    },
  },
  styles: {
    global: {
      body: {
        bg: "linear-gradient(to bottom, #001428, #000a14)",
        color: "white",
      },
    },
  },
});
