import React, { useState, useEffect } from "react";
import { Box, Heading, Text, useMediaQuery } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { getText } from "./translations";
import image1 from "./files/feature001.webp";
import image2 from "./files/feature002.webp";
import image3 from "./files/feature003.webp";
import image4 from "./files/feature004.webp";
import { ChevronLeft, ChevronRight } from "lucide-react";

const features = [
  {
    id: "find-vibe",
    title: "findVibe",
    image: image1,
  },
  {
    id: "express-self",
    title: "expressSelf",
    image: image2,
  },
  {
    id: "earn-rewards",
    title: "earnRewards",
    image: image3,
  },
  {
    id: "anonymous-posting",
    title: "anonymousPosting",
    image: image4,
  },
];

export default function Component({ currentLanguage = "en" }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isTablet] = useMediaQuery("(max-width: 768px)");
  const [isSmallScreen] = useMediaQuery("(max-width: 1024px)");

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + features.length) % features.length
    );
  };

  const getVisibleFeatures = () => {
    if (isMobile) return [features[currentIndex]];
    if (isTablet)
      return [
        features[currentIndex],
        features[(currentIndex + 1) % features.length],
      ];

    const visibleFeatures = [];
    for (let i = -1; i <= 1; i++) {
      const index = (currentIndex + i + features.length) % features.length;
      visibleFeatures.push({ ...features[index], offset: i });
    }
    return visibleFeatures;
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        prevSlide();
      } else if (event.key === "ArrowRight") {
        nextSlide();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <Box
      minHeight={{ base: "auto", md: "100vh" }}
      id="features"
      textAlign="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py={8}
      px={4}
      bg="background.default"
      position="relative"
      overflow="hidden"
      perspective="1000px"
    >
      <Heading as="h2" size="xl" mb={6} color="white">
        {getText("features", currentLanguage)}
      </Heading>
      <Box
        position="relative"
        width="100%"
        height={{ base: "250px", sm: "300px", md: "400px", lg: "500px" }}
        maxWidth={{ base: "100%", sm: "90%", md: "80%", lg: "1200px" }}
        mx="auto"
      >
        {getVisibleFeatures().map((feature) => (
          <motion.div
            key={feature.id}
            style={{
              position: "absolute",
              width: isMobile
                ? "90%"
                : isTablet
                ? "45%"
                : feature.offset === 0
                ? "400px"
                : "300px",
              height: isMobile
                ? "90%"
                : isTablet
                ? "90%"
                : feature.offset === 0
                ? "100%"
                : "80%",
              top: "50%",
              left: "50%",
              zIndex: feature.offset === 0 ? 1 : 0,
              perspective: "1000px",
            }}
            animate={{
              x: isMobile
                ? "-50%"
                : isTablet
                ? `calc(${feature.offset * 110}% - 50%)`
                : `calc(${feature.offset * 110}% - 50%)`,
              y: "-50%",
              scale: isMobile || isTablet ? 1 : feature.offset === 0 ? 1 : 0.8,
              opacity:
                isMobile || isTablet ? 1 : feature.offset === 0 ? 1 : 0.7,
              rotateY: isSmallScreen ? 0 : feature.offset * -15,
            }}
            transition={{ duration: 0.5 }}
            whileHover={{
              scale: 1.05,
              rotateY: 0,
              transition: { duration: 0.3 },
            }}
          >
            <Box
              bg="white"
              borderRadius="8px"
              boxShadow="0 10px 20px rgba(0, 0, 0, 0.2)"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              border="2px solid #FFFFFF"
              width="100%"
              height="100%"
              overflow="hidden"
              position="relative"
              transform={isSmallScreen ? "none" : "translateZ(50px)"}
              transition="transform 0.3s ease-out"
              _hover={{
                transform: isSmallScreen
                  ? "scale(1.05)"
                  : "translateZ(75px) rotateX(5deg) rotateY(5deg)",
              }}
            >
              <Box
                position="absolute"
                top="5%"
                left="50%"
                transform="translateX(-50%) translateZ(30px)"
                zIndex={2}
                padding="8px 16px"
                borderRadius="4px"
                backgroundColor="rgba(0, 0, 0, 0.7)"
              >
                <Text
                  fontWeight="900"
                  color="white"
                  fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
                  textAlign="center"
                  textShadow="0 2px 4px rgba(0, 0, 0, 0.5)"
                  letterSpacing="1px"
                  transform="perspective(500px) rotateX(10deg)"
                  style={{
                    WebkitTextStroke: "1px rgba(255, 255, 255, 0.3)",
                  }}
                >
                  {getText(feature.title, currentLanguage)}
                </Text>
              </Box>
              <Box
                height="100%"
                width="100%"
                overflow="hidden"
                borderRadius="4px"
                position="relative"
              >
                <LazyLoadImage
                  src={feature.image}
                  alt={getText(feature.title, currentLanguage)}
                  effect="blur"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "4px",
                    transform: "scale(1.1)",
                    transition: "transform 0.3s ease-out",
                  }}
                  wrapperProps={{
                    style: {
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                />
              </Box>
            </Box>
          </motion.div>
        ))}
      </Box>
      <Box
        position="absolute"
        left={{ base: "5px", sm: "10px", md: "20px" }}
        top="50%"
        transform="translateY(-50%)"
        zIndex={2}
      >
        <ChevronLeft
          size={isMobile ? 24 : isTablet ? 30 : 40}
          color="white"
          onClick={prevSlide}
          style={{ cursor: "pointer" }}
        />
      </Box>
      <Box
        position="absolute"
        right={{ base: "5px", sm: "10px", md: "20px" }}
        top="50%"
        transform="translateY(-50%)"
        zIndex={2}
      >
        <ChevronRight
          size={isMobile ? 24 : isTablet ? 30 : 40}
          color="white"
          onClick={nextSlide}
          style={{ cursor: "pointer" }}
        />
      </Box>
    </Box>
  );
}
