import React from "react";
import { Box, Text, Heading } from "@chakra-ui/react";
import { getText } from "./translations"; // Adjust the import path as needed

export default function AboutUs({ currentLanguage }) {
  return (
    <Box as="section" p={10} textAlign="center">
      <Heading as="h2" size="xl" mb={4} color="white">
        {getText("aboutUs", currentLanguage)}{" "}
        {/* Use a new key for "About Us" */}
      </Heading>
      <Text fontSize="lg" color="white">
        {getText("aboutUsDescription", currentLanguage)}{" "}
        {/* Use a new key for the description */}
      </Text>
    </Box>
  );
}
