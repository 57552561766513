import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Box,
} from "@chakra-ui/react";
import { getText } from "./translations";

export default function NotificationModal({
  isOpen,
  onClose,
  currentLanguage,
}) {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the form submission here
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="gray.800"
        color="white"
        borderRadius="md"
        boxShadow="lg"
        width={{ base: "80%", md: "400px" }} // Responsive width
        maxWidth="500px" // Optional max width
      >
        <ModalHeader>{getText("getNotified", currentLanguage)}</ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FormControl isRequired>
              <FormLabel>{getText("email", currentLanguage)}</FormLabel>
              <Input
                type="email"
                placeholder={getText("emailPlaceholder", currentLanguage)}
                bg="gray.700"
                color="white"
                borderColor="gray.600"
                _placeholder={{ color: "gray.400" }}
                _hover={{ borderColor: "gray.400" }}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{getText("message", currentLanguage)}</FormLabel>
              <Textarea
                placeholder={getText("messagePlaceholder", currentLanguage)}
                bg="gray.700"
                color="white"
                borderColor="gray.600"
                _placeholder={{ color: "gray.400" }}
                _hover={{ borderColor: "gray.400" }}
              />
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            {getText("submit", currentLanguage)}
          </Button>
          <Button variant="ghost" onClick={onClose}>
            {getText("cancel", currentLanguage)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
