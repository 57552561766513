import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Heading,
  Flex,
  IconButton,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import { FaLinkedin, FaInstagram } from "react-icons/fa";
import { getText } from "./translations";
import privacyPolicy from "./privacypolicy";

export default function Footer({ currentLanguage }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent] = useState("");

  const openModalWithContent = (content) => {
    setModalContent(content);
    onOpen();
  };

  // Function to scroll to the top of the page
  const scrollToHomePage = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Box
      as="footer"
      mt={8}
      bg="background.default"
      py={6}
      boxShadow="0 -4px 6px rgba(255, 255, 255, 0.3)"
    >
      <Container maxW="container.lg">
        <Grid
          templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
          gap={4}
          justifyContent="center"
        >
          <Box textAlign="center">
            <Heading as="h3" size="md" mb={4} color="white">
              {getText("connectWithUs", currentLanguage)}
            </Heading>
            <Flex justifyContent="center" gap={2}>
              <IconButton
                as="a"
                href="https://www.linkedin.com/company/vibeinn"
                target="_blank"
                rel="noopener noreferrer"
                icon={<FaLinkedin size="md" />}
                aria-label="LinkedIn"
                bg="transparent"
                color="white"
                _hover={{ color: "#0052cc" }}
              />
              <IconButton
                as="a"
                href="mailto:vibeinn05@gmail.com"
                icon={<EmailIcon boxSize={10} />}
                aria-label="Email"
                bg="transparent"
                color="white"
                _hover={{ color: "#0052cc" }}
              />
              <IconButton
                as="a"
                href="https://www.instagram.com/vibeinn.online?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                target="_blank"
                rel="noopener noreferrer"
                icon={<FaInstagram size="md" />}
                aria-label="Instagram"
                bg="transparent"
                color="white"
                _hover={{ color: "#0052cc" }}
              />
            </Flex>
          </Box>
          <Box textAlign="center">
            <Heading as="h3" size="sm" color="white">
              {getText("copyright", currentLanguage)}
            </Heading>
          </Box>
          <Box textAlign="center">
            {/* Privacy Policy - Open modal with content */}
            <Link
              href="#"
              mr={2}
              color="white"
              onClick={() => openModalWithContent(privacyPolicy)}
            >
              {getText("privacyPolicy", currentLanguage)}
            </Link>
            {/* Terms of Service - Scroll to homepage */}
            <Link href="#" color="white" onClick={scrollToHomePage}>
              {getText("termsOfService", currentLanguage)}
            </Link>
          </Box>
        </Grid>
      </Container>

      {/* Modal */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justify="center" width="100%">
              <Text color="black">Privacy Policy</Text>
            </Flex>
            <ModalCloseButton />
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody maxHeight="75vh" overflowY="auto">
            {/* Use Chakra Text component for rendering the content */}
            <Text whiteSpace="pre-wrap" color="gray.700" fontSize="sm">
              {modalContent}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
