export const translations = {
  en: {
    welcome: "Welcome to Vibeinn",
    tagline:
      "Find Your Vibe, Spark Conversations, and Get Rewarded with Vibeinn!",
    notifyMe: "Notify Me",
    features: "Features",
    findVibe: "Find Your Vibe Instantly",
    expressSelf: "Express Your True Self",
    earnRewards: "Earn Rewards & Become a Celebrity",
    anonymousPosting: "Anonymous Posting and Chatting",
    connectWithUs: "Connect with us",
    privacyPolicy: "Privacy Policy",
    termsOfService: "Terms of Service",
    copyright: "© 2024 Vibeinn. All rights reserved.",
    selectLanguage: "Select Language",
    getNotified: "Get Notified",
    email: "Email",
    emailPlaceholder: "Enter your email",
    message: "Message (optional)",
    messagePlaceholder: "Any questions or comments?",
    submit: "Submit",
    cancel: "Cancel",
    menu: "Menu",
    home: "Home",
    aboutUs: "About Us",
    aboutUsDescription:
      "Ground-breaking AI-powered social media platform which lets you speak your heart out on any thought without the fear of being judged. Increase your social relevance by making a customized profile that reflects the funnier and even darker side of yours, and connect with similar profiles. The icing on the cake is you get a chance to WIN attractive REWARDS if you delight or help someone with your words.",
  },
  es: {
    welcome: "Bienvenido a Vibeinn",
    tagline:
      "¡Encuentra tu vibra, inicia conversaciones y obtén recompensas con Vibeinn!",
    notifyMe: "Notifícame",
    features: "Características",
    findVibe: "Encuentra tu vibra al instante",
    expressSelf: "Exprésate libremente",
    earnRewards: "Gana recompensas y conviértete en una celebridad",
    anonymousPosting: "Publicaciones y chat anónimos",
    connectWithUs: "Conéctate con nosotros",
    privacyPolicy: "Política de privacidad",
    termsOfService: "Términos de servicio",
    copyright: "© 2024 Vibeinn. Todos los derechos reservados.",
    selectLanguage: "Seleccionar idioma",
    getNotified: "Recibir notificaciones",
    email: "Correo electrónico",
    emailPlaceholder: "Ingresa tu correo electrónico",
    message: "Mensaje (opcional)",
    messagePlaceholder: "¿Alguna pregunta o comentario?",
    submit: "Enviar",
    cancel: "Cancelar",
    menu: "Menú",
    home: "Inicio",
    aboutUs: "Sobre nosotros",
    aboutUsDescription:
      "Plataforma de redes sociales impulsada por IA que te permite expresar tus pensamientos sin miedo a ser juzgado. Aumenta tu relevancia social creando un perfil personalizado que refleje tu lado más divertido e incluso más oscuro, y conéctate con perfiles similares. La guinda del pastel es que tienes la oportunidad de GANAR recompensas atractivas si deleitas o ayudas a alguien con tus palabras.",
  },
  fr: {
    welcome: "Bienvenue sur Vibeinn",
    tagline:
      "Trouvez votre vibe, lancez des conversations et soyez récompensé avec Vibeinn !",
    notifyMe: "Me notifier",
    features: "Fonctionnalités",
    findVibe: "Trouvez votre vibe instantanément",
    expressSelf: "Exprimez votre vrai moi",
    earnRewards: "Gagnez des récompenses et devenez une célébrité",
    anonymousPosting: "Publication et chat anonymes",
    connectWithUs: "Connectez-vous avec nous",
    privacyPolicy: "Politique de confidentialité",
    termsOfService: "Conditions d'utilisation",
    copyright: "© 2024 Vibeinn. Tous droits réservés.",
    selectLanguage: "Sélectionner la langue",
    getNotified: "Être notifié",
    email: "E-mail",
    emailPlaceholder: "Entrez votre e-mail",
    message: "Message (facultatif)",
    messagePlaceholder: "Des questions ou des commentaires ?",
    submit: "Envoyer",
    cancel: "Annuler",
    menu: "Menu",
    home: "Accueil",
    aboutUs: "À propos de nous",
    aboutUsDescription:
      "Plateforme de médias sociaux alimentée par l'IA qui vous permet de vous exprimer librement sans craindre d'être jugé. Augmentez votre pertinence sociale en créant un profil personnalisé qui reflète votre côté le plus drôle et même le plus sombre, et connectez-vous avec des profils similaires. La cerise sur le gâteau est que vous avez la chance de GAGNER des RÉCOMPENSES attrayantes si vous ravissez ou aidez quelqu'un avec vos mots.",
  },
  de: {
    welcome: "Willkommen bei Vibeinn",
    tagline:
      "Finde deinen Vibe, starte Gespräche und werde mit Vibeinn belohnt!",
    notifyMe: "Benachrichtige mich",
    features: "Funktionen",
    findVibe: "Finde deinen Vibe sofort",
    expressSelf: "Drücke dein wahres Ich aus",
    earnRewards: "Verdiene Belohnungen und werde ein Star",
    anonymousPosting: "Anonymes Posten und Chatten",
    connectWithUs: "Verbinde dich mit uns",
    privacyPolicy: "Datenschutzrichtlinie",
    termsOfService: "Nutzungsbedingungen",
    copyright: "© 2024 Vibeinn. Alle Rechte vorbehalten.",
    selectLanguage: "Sprache auswählen",
    getNotified: "Benachrichtigt werden",
    email: "E-Mail",
    emailPlaceholder: "Gib deine E-Mail-Adresse ein",
    message: "Nachricht (optional)",
    messagePlaceholder: "Fragen oder Kommentare?",
    submit: "Absenden",
    cancel: "Abbrechen",
    menu: "Menü",
    home: "Startseite",
    aboutUs: "Über uns",
    aboutUsDescription:
      "Bahnbrechende KI-gesteuerte Social-Media-Plattform, die es dir ermöglicht, deine Gedanken ohne Angst vor Urteilen auszudrücken. Erhöhe deine soziale Relevanz, indem du ein benutzerdefiniertes Profil erstellst, das deine lustige und sogar dunkle Seite widerspiegelt, und verbinde dich mit ähnlichen Profilen. Das Sahnehäubchen ist, dass du die Chance hast, attraktive BELohnungen zu GEWINNEN, wenn du jemanden mit deinen Worten erfreust oder hilfst.",
  },
  hi: {
    welcome: "Vibeinn में आपका स्वागत है",
    tagline:
      "अपना वाइब खोजें, बातचीत शुरू करें, और Vibeinn के साथ पुरस्कार प्राप्त करें!",
    notifyMe: "मुझे सूचित करें",
    features: "विशेषताएँ",
    findVibe: "तुरंत अपना वाइब खोजें",
    expressSelf: "अपने सच्चे स्वयं को व्यक्त करें",
    earnRewards: "पुरस्कार कमाएं और सेलिब्रिटी बनें",
    anonymousPosting: "अनाम पोस्टिंग और चैटिंग",
    connectWithUs: "हमसे जुड़ें",
    privacyPolicy: "गोपनीयता नीति",
    termsOfService: "सेवा की शर्तें",
    copyright: "© 2024 Vibeinn. सर्वाधिकार सुरक्षित।",
    selectLanguage: "भाषा चुनें",
    getNotified: "सूचित हों",
    email: "ईमेल",
    emailPlaceholder: "अपना ईमेल दर्ज करें",
    message: "संदेश (वैकल्पिक)",
    messagePlaceholder: "कोई प्रश्न या टिप्पणी?",
    submit: "जमा करें",
    cancel: "रद्द करें",
    menu: "मेनू",
    home: "होम",
    aboutUs: "हमारे बारे में",
    aboutUsDescription:
      "क्रांतिकारी एआई-संचालित सोशल मीडिया प्लेटफ़ॉर्म जो आपको बिना किसी न्याय के अपने दिल की बात कहने की अनुमति देता है। एक कस्टम प्रोफ़ाइल बनाकर अपनी सामाजिक प्रासंगिकता बढ़ाएं जो आपके मजेदार और यहां तक कि गहरे पक्ष को दर्शाता है, और समान प्रोफाइल के साथ कनेक्ट करें। बात का icing यह है कि आपको अपने शब्दों से किसी को खुश या मदद करके आकर्षक पुरस्कार जीतने का मौका मिलता है।",
  },
};

export const getText = (key, language) => {
  return translations[language][key] || translations.en[key] || key;
};
