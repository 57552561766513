const termsOfServiceContent = `
Last updated: October 01, 2024
This Privacy Notice for ConnectUth (doing business as Vibeinn) ("we," "us," or "our") describes how and why we might access, collect, store, use, and/or share ("process") your personal information when you use our services ("Services"), including when you:
•	Visit our website at www.vibeinn.online or any website of ours that links to this Privacy Notice.
•	Download and use our mobile application (Vibeinn) or any other application of ours that links to this Privacy Notice.
•	Use Vibeinn, a groundbreaking AI-powered social media platform that allows you to express yourself freely, enhance your social relevance with a customized profile, and connect with like-minded individuals. Plus, you have a chance to WIN attractive REWARDS by delighting or helping others with your words.
•	Engage with us in other related ways, including sales, marketing, or events.
Questions or Concerns?
Reading this Privacy Notice will help you understand your privacy rights and choices. We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at vibeinn05@gmail.com.
Summary of Key Points
This summary provides key points from our Privacy Notice. You can find more details about any of these topics by clicking the link following each key point or by using our table of contents below to locate the section you are interested in.
1.	What personal information do we process?
When you visit, use, or navigate our Services, we may process personal information based on your interactions, choices, and the products and features you use. Learn more about the personal information you disclose to us.
2.	Do we process any sensitive personal information?
Some information may be considered "special" or "sensitive" in certain jurisdictions, such as your racial or ethnic origins, sexual orientation, and religious beliefs. We do not process sensitive personal information.
Do We Collect Any Information from Third Parties?
•	We do not collect any information from third parties.
How Do We Process Your Information?
We process your information to:
•	Provide, improve, and administer our Services
•	Communicate with you
•	Ensure security and prevent fraud
•	Comply with legal obligations
We may also process your information for other purposes with your consent. We only process your information when we have a valid legal reason to do so. Learn more about how we process your information.
In What Situations and With Which Parties Do We Share Personal Information?
•	We may share information in specific situations and with certain third parties. Learn more about when and with whom we share your personal information.
How Do We Keep Your Information Safe?
•	We have established adequate organizational and technical measures to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure. Therefore, we cannot promise or guarantee that unauthorized third parties will not be able to defeat our security measures and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.
What Are Your Rights?
•	Depending on your geographical location, applicable privacy laws may grant you certain rights regarding your personal information. Learn more about your privacy rights.
How Do You Exercise Your Rights?
•	The easiest way to exercise your rights is by visiting www.vibeinn.online or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
Want to learn more about what we do with any information we
collect? Review the Privacy Notice in full. 
Table of Contents
1.	What Information Do We Collect?
2.	How Do We Process Your Information?
3.	When and With Whom Do We Share Your Personal Information?
4.	Do We Use Cookies and Other Tracking Technologies?
5.	Do We Offer Artificial Intelligence-Based Products?
6.	How Do We Handle Your Social Logins?
7.	How Long Do We Keep Your Information?
8.	How Do We Keep Your Information Safe?
9.	Do We Collect Information from Minors?
10.	What Are Your Privacy Rights?
11.	Controls for Do-Not-Track Features
12.	Do We Make Updates to This Notice?
13.	How Can You Contact Us About This Notice?
14.	How Can You Review, Update, or Delete the Data We Collect from You?
1. What Information Do We Collect?
Personal Information You Disclose to Us
In Short: We collect personal information that you provide to us.
We collect personal information that you voluntarily provide when you:
•	Register on the Services
•	Express an interest in obtaining information about us or our products and Services
•	Participate in activities on the Services
•	Contact us for any reason
Personal Information Provided by You
The personal information we collect depends on the context of your interactions with us, the choices you make, and the products and features you use. The personal information we may collect includes:
•	Names
•	Phone numbers
•	Email addresses
•	Job titles
•	Usernames
•	Passwords
•	Contact preferences
•	Contact or authentication data
•	Billing addresses
Sensitive Information
•	We do not process sensitive information.
Payment Data
•	If you choose to make purchases, we may collect data necessary to process your payment, such as your payment instrument number and the security code associated with your payment instrument. All payment data is handled and stored by Razorpay. You may find their privacy notice here.
Social Media Login Data
•	We may provide you with the option to register using your existing social media account details, such as Facebook or X. If you choose this option, we will collect certain profile information from the social media provider as described in the section titled "How Do We Handle Your Social Logins?" below.
Application Data
•	If you use our application(s), we may also collect the following information if you provide us access or permission:
o	Push Notifications: We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out of these communications, you can turn them off in your device's settings. This information is primarily needed for the security and operation of our application(s), troubleshooting, and for our internal analytics and reporting purposes.
All personal information that you provide to us must be true, complete, and accurate. You must notify us of any changes to your personal information.

Information Automatically Collected
In Short: Some information—such as your Internet Protocol (IP) address and/or browser and device characteristics—is collected automatically when you visit our Services.
We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include:
•	Device and usage information (e.g., IP address, browser and device characteristics)
•	Operating system
•	Language preferences
•	Referring URLs
•	Device name
•	Country and location
•	Information about how and when you use our Services
•	Other technical information
This information is primarily needed to maintain the security and operation of our Services, as well as for internal analytics and reporting purposes.
Cookies and Similar Technologies
Like many businesses, we also collect information through cookies and similar technologies. The information we collect includes:
•	Types of Cookies and Technologies Used: [Details about the types of cookies and technologies used]
•	Purpose of Each Type of Cookie: [Purpose of each type of cookie]
•	Managing Cookie Preferences: [How users can manage their cookie preferences]
For more information on cookies and similar technologies, please refer to our full Privacy Notice.
Log and Usage Data
Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services. This log data may include:
•	Your IP address
•	Device information
•	Browser type and settings
•	Information about your activity in the Services (such as date/time stamps, pages and files viewed, searches, and actions taken)
•	Device event information (such as system activity, error reports, and hardware settings)
Device Data
We collect device data such as information about your computer, phone, tablet, or other devices you use to access the Services. Depending on the device, this data may include:
•	IP address (or proxy server)
•	Device and application identification numbers
•	Location
•	Browser type
•	Hardware model
•	Internet service provider and/or mobile carrier
•	Operating system
•	System configuration information
Location Data
We collect location data regarding your device’s location, which can be either precise or imprecise. The amount of information we collect depends on the type and settings of the device you use. For instance, we may use GPS and other technologies to gather geolocation data that reveals your current location (based on your IP address). You can opt out of this data collection by refusing access or disabling the Location setting on your device. However, opting out may limit your ability to use certain aspects of the Services.
collect, understand how we have processed it, correct any inaccuracies, or delete your personal information. You may also withdraw your consent for our processing of your personal information. These rights may be subject to limitations under applicable law. To request access to review, update, or delete your personal information, please visit: www.vibeinn.online
`;

export default termsOfServiceContent;
